.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 320px;
  margin: 0 auto;
  width: 100%;

  &__buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }

  &__links {
    gap: 1rem;
    font-size: 0.75rem;
    color: var(--text-secondary);
    text-transform: uppercase;
    // letter-spacing: 0.05em;
    font-weight: 500;
    text-align: center;

    a {
      font-size: 0.75rem;
      color: var(--primary);
      font-weight: 700;

      &:hover {
        color: var(--primary-hover);
      }
    }
  }
}
